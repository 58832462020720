<template>
    <div v-if="recipient && sender">
        <div class="card">
            <div class="card-header small-card-header d-flex align-items-center">
                <h6 class="fw-bold m-0">Recipient</h6>
                <div class="ms-auto">
                    <button v-show="recipient.clientId !== '' && !recipientAggregation" type="button" @click="getRecipientAggregation" class="btn btn-sm btn-primary me-1 tiny-button"><i class="fas fa-sync"></i> Activity</button>
                    <button type="button" @click="openFindRecipientModal" class="btn btn-sm btn-primary me-1 tiny-button"><i class="fas fa-search"></i> Find Recipient</button>
                    <button type="button" @click="clearRecipient" class="btn btn-sm btn-outline-primary ms-1 tiny-button">Clear</button>
                </div>
            </div>
            <div class="card-body p-2">
                <form autocomplete="off">
                    <div class="row small-row alert alert-primary" v-if="recipientAggregation">
                        <div class="col-md-4">
                            <label class="fw-bold">Activity in past 30 days</label><br />
                            <!-- ({{ recipientAggregation.transactions30Days }})-->
                            ${{ recipientAggregation.aggregationSent30DaysFormated }}
                            <!-- / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived30DaysFormated }} -->
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold text-center">90 days</label><br />
                            <!-- ({{ recipientAggregation.transactions90Days }}) -->
                            ${{ recipientAggregation.aggregationSent90DaysFormated }}
                            <!-- / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived90DaysFormated }} -->
                        </div>
                        <div class="col-md-4">
                            <label class="fw-bold text-end">365 days</label><br />
                            <!-- ({{ recipientAggregation.transactions365Days }})-->
                            ${{ recipientAggregation.aggregationSent365DaysFormated }}
                            <!-- / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived365DaysFormated }} -->
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col">
                            <label for="fullName" class="form-label m-0 tiny-font">Full name</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" @keyup.enter="openFindRecipientModal" v-model="recipient.fullName" class="form-control form-control-sm" id="fullName" placeholder="First and last name" :disabled="!isNewRecipient" />
                        </div>
                        <div class="col-md-4">
                            <label class="form-label m-0 tiny-font">Customer number</label>
                            <span v-if="!isNewRecipient" class="badge bg-light text-black form-control fs-5 py-1 border-light">{{ recipient.clientId }}</span>
                            <input id="customerNumber" autocomplete="none" v-else type="text" v-model="recipient.clientIdSearch" @keydown="brazilNumberInputMask" class="form-control form-control-sm" />
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-md-6">
                            <label for="company" class="form-label m-0 tiny-font">Company name</label>
                            <input autocomplete="none" type="text" v-model="recipient.company" class="form-control form-control-sm" id="company" placeholder="" :disabled="!isNewRecipient" />
                        </div>
                        <div class="col-sm-6 col-md-3" v-show="showCpfCnpj">
                            <label for="cpfCnpj" class="form-label m-0 tiny-font">CPF or CNPJ</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input id="cpfCnpj" autocomplete="none" type="text" @keyup.enter="openFindRecipientModal" v-model="recipient.cpfOrCnpj" @keydown="brazilNumberInputMask" class="form-control form-control-sm" />
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label for="telephone" class="form-label m-0 tiny-font">Telephone</label><span v-if="recipient.countryId != 42" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input id="telephone" autocomplete="none" type="text" v-model="recipient.phone" @keydown="brazilNumberInputMask" class="form-control form-control-sm" />
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-sm-4">
                            <label for="country" class="form-label m-0 tiny-font">Country</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <select-country class="form-select-sm" :data="countries" v-model="recipient.countryId" :value="recipient.countryId" @change="changeCountry(recipient.countryId)" id="country"></select-country>
                        </div>
                        <div class="col-sm-4">
                            <label for="zip" class="form-label m-0 tiny-font">Zip code</label>
                            <input id="zip" autocomplete="none" type="text" v-model="recipient.zip" @keydown="brazilNumberInputMask" class="form-control form-control-sm" />
                        </div>
                        <div class="col-sm-4">
                            <label for="countryOfPayment" class="form-label m-0 tiny-font">Country of payment</label>
                            <select-country class="form-select-sm" :data="countries" v-model="recipient.countryOfPaymentId" :value="recipient.countryOfPaymentId" @change="changeCountry(recipient.countryOfPaymentId)" id="countryOfPayment"></select-country>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col-md-6">
                            <label for="street" class="form-label m-0 tiny-font">Street</label>
                            <input autocomplete="none" type="text" v-model="recipient.address" class="form-control form-control-sm" id="street" placeholder="" />
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label for="city" class="form-label m-0 tiny-font">City</label><span v-if="recipient.countryId == 2" class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" v-model="recipient.city" class="form-control form-control-sm" id="city" placeholder="" />
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label for="state" class="form-label m-0 tiny-font">State / Province</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                            <input autocomplete="none" type="text" v-model="recipient.stateName" class="form-control form-control-sm" id="state" placeholder="" v-show="showStateInputText" />
                            <select id="states" class="form-select form-select-sm" v-model="recipient.stateIsoCode" @change="changeState" v-show="!showStateInputText">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in states" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row small-row">
                        <div class="col">
                            <label for="email" class="form-label m-0 tiny-font">Email</label>
                            <input id="email" autocomplete="none" type="text" v-model="recipient.email" placeholder="" @blur="emailInputValidation" :class="{ 'border-danger': errors.email.error }" class="form-control form-control-sm" />
                            <span class="text-danger tiny-font" v-show="errors.email.error">{{ errors.email.message }}</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <modal-find-recipient ref="modalFindRecipient" @selected="selectedRecipient" :sender-id="sender.clientId" :customer-number="recipient.clientIdSearch" :full-name="recipient.fullName" :telephone="recipient.phone" :company-name="recipient.company" :cpf-cnpj="recipient.cpfOrCnpj"></modal-find-recipient>
    </div>
    <loading v-else :show="loading" />
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import SelectCountry from '@/components/SelectCountry';
import ModalFindRecipient from '@/views/order/modals/FindRecipient';

export default {
    name: 'NewOrderRecipient',
    components: {
        Loading,
        SelectCountry,
        ModalFindRecipient,
    },
    data() {
        return {
            countries: [],
            states: [],
            errors: {
                email: { error: false, message: '' },
            },
            recipientAggregation: null,
            loadingRecipientAggregation: false,
            brazilCountryId: 42,
        };
    },
    mounted() {
        this.loadCountries();
        if (!this.recipient || !this.recipient.countryId) {
            this.setDefaultCountryToBrazil();
        } else {
            this.loadStates();
        }
    },
    methods: {
        loadRecipient(recipientId) {
            this.loading = true;
            axios
                .get('/niloagent/createorder/find/recipient', {
                    params: {
                        ClientId: recipientId,
                        pageNumber: 1,
                    },
                })
                .then((response) => {
                    if (response.data.result.length > 0) {
                        this.$store.commit('simpleOrder/newPayment');
                        this.$store.commit('simpleOrder/setAdditionalInformation');
                        this.$store.commit('simpleOrder/setRecipient', response.data.result[0]);
                        this.checkCountriesSelected();
                    }
                })
                .catch((error) => {
                    this.$store.commit('simpleOrder/newRecipient');
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setDefaultCountryToBrazil() {
            this.recipient.countryId = 42;
            this.recipient.countryOfPaymentId = 42;
        },
        openFindRecipientModal() {
            this.$refs.modalFindRecipient.show(this.senderId);
        },
        selectedRecipient(recipient) {
            this.$store.commit('simpleOrder/newPayment');
            this.$store.commit('simpleOrder/setAdditionalInformationKeepingOccupation');
            this.$store.commit('simpleOrder/setRecipient', recipient);
            this.checkCountriesSelected();
            this.$refs.modalFindRecipient.close();
        },
        async clearRecipient() {
            await this.$store.commit('simpleOrder/newRecipient');
            this.$store.commit('simpleOrder/newPayment');
            this.$store.commit('simpleOrder/setAdditionalInformationKeepingOccupation');
            this.aggregation30DaysFormated = '';
            this.recipientAggregation = null;
        },
        loadCountries() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/destinycountries?onlyActiveCountry=true')
                .then((response) => {
                    this.countries = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`country: ${error.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadStates() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/recipient/state', {
                    params: {
                        countryId: this.recipient.countryId,
                    },
                })
                .then((response) => {
                    this.states = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`country: ${error.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkCountriesSelected() {
            if (this.recipient.countryId > 0 && this.recipient.countryOfPaymentId <= 0) this.recipient.countryOfPaymentId = this.recipient.countryId;
            if (this.recipient.countryId <= 0 && this.recipient.countryOfPaymentId > 0) this.recipient.countryId = this.recipient.countryOfPaymentId;
        },
        changeCountry(value) {
            this.recipient.countryId = value;
            this.recipient.countryOfPaymentId = value;
            if (value !== 42) {
                this.recipient.cp;
            }
        },
        changeState() {
            this.recipient.stateName = this.selectedRecipientState.value;
        },
        getRecipientAggregation() {
            this.loadingRecipientAggregation = true;
            axios
                .get(`/niloagent/order/-1/detail/recipientaggregation/${this.recipient.clientId}`)
                .then((response) => {
                    this.recipientAggregation = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loadingRecipientAggregation = false;
                });
        },
        numberInputMask(event) {
            const key = event.key;
            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
                return;
            }

            const allowedCommandKeys = ['c', 'v', 'a', 'x'];
            const isCtrlOrCmd = event.ctrlKey || event.metaKey;
            if (isCtrlOrCmd && allowedCommandKeys.includes(key.toLowerCase())) {
                return;
            }

            if (!/[0-9]/.test(key)) {
                event.preventDefault();
                return;
            }
        },
        emailInputValidation(event) {
            let email = event.target.value;
            const emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
            if (!emailRegex.test(email)) {
                this.errors.email.error = true;
                this.errors.email.message = 'Invalid email';
            } else {
                this.errors.email.error = false;
                this.errors.email.message = '';
            }
        },
        brazilNumberInputMask(event) {
            if (this.recipient.countryId != this.brazilCountryId) {
                return;
            }
            this.numberInputMask(event);
        },
    },
    computed: {
        recipient() {
            return this.$store.state.simpleOrder.recipient;
        },
        sender() {
            return this.$store.state.simpleOrder.sender;
        },
        isNewRecipient() {
            return !this.recipient.clientId > 0;
        },
        showCpfCnpj() {
            return parseInt(this.recipient.countryOfPaymentId) === 42;
        },
        showStateInputText() {
            return this.states.length === 0;
        },
        selectedRecipientState() {
            return Object.keys(this.recipient).length > 0 && this.states.length > 0 && this.recipient.stateIsoCode > '' ? this.states.find((item) => item.id === this.recipient.stateIsoCode) : { id: this.recipient.stateIsoCode, value: this.recipient.stateName };
        },
        recipientCountry() {
            return this.recipient.countryId;
        },
        senderId() {
            return this.sender.clientId;
        },
        loading: {
            get: function () {
                return this.$store.state.simpleOrder.loading;
            },
            set: function (modifiedValue) {
                this.$store.state.simpleOrder.loading = modifiedValue;
            },
        },
    },
    watch: {
        recipientCountry(countryId) {
            if (!countryId && countryId !== 0) {
                this.setDefaultCountryToBrazil();
            } else {
                this.loadStates();
            }
        },
        senderId(value) {
            this.recipientAggregation = null;
            this.aggregation30DaysFormated = '';
            if (value > 0) this.openFindRecipientModal();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/simpleOrder.scss';
</style>
