<template>
    <div>
        <slot v-if="err" name="error" v-bind:err="err">
            <div class="bg-login" v-if="err" name="error" v-bind:err="err">
                <div class="gradient vh-100 position-relative">
                    <div class="container px-4 p-sm-0">
                        <div class="row justify-content-center vh-100 align-items-center">
                            <div class="col-lg-8 col-md-10">
                                <div class="text-center">
                                    <img src="@/assets/img/logo-white-inter.png" class="logo w-100 pb-5" alt="Inter&Co Agents" />
                                </div>
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="p-xl-5 p-lg-4">
                                            <div class="column justify-content-center">
                                                <div class="d-flex flex-column justify-content-end">
                                                    <div class="row text-start justify-content-center title">Sorry! Something went wrong</div>
                                                    <div class="row text-start justify-content-center">The system is unavailable to perform this action. Please, contact support and report the following error code:</div>
                                                    <div class="row text-start justify-content-center code">
                                                        {{ errorCode }}
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column align-items-center">
                                                    <button class="btn btn-primary text-uppercase mt-4 align-middle w-25" id="refresh" @click="refresh"><i class="fas fa-sync"></i> Reload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <slot v-else></slot>
    </div>
</template>

<script>
import { TrackData } from '@interco/track';

export default {
    name: 'error-boundary',
    props: {
        stopPropagation: Boolean,
    },
    methods: {
        refresh() {
            this.err = false;
            this.errorCode = '';
        },
    },
    data() {
        return {
            err: false,
            errorCode: '',
        };
    },
    errorCaptured(err) {
        //this.err = err;
        const hexId = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
        this.errorCode = hexId;

        TrackData.error({
            transactionSubType: 'CODE_ERROR',
            INFO: err.stack,
            TYPE_ERROR: err.message,
            ERROR_ID: hexId,
        });

        return !this.stopPropagation;
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

.card {
    margin-bottom: 100px;
}

.logo {
    max-width: 168px;
}

.gradient {
    background-color: $primary;
}

.title {
    font-size: 1.5rem;
}

.code {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 1.5rem;
}
</style>
