<template>
    <div class="row">
        <div class="mb-4 col-6" :class="column" v-show="showNewOrder">
            <router-link :to="{ name: 'Simple Order' }" name="btnSimpleNewOrder">
                <MenuButton>
                    <template v-slot:label>New Order</template>
                    <template v-slot:icon><i class="fas fa-money-bill"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showNewOrder" style="position: relative">
            <router-link :to="{ name: 'Compliance Approval Task' }" name="btnComplianceList">
                <MenuButton>
                    <template v-slot:label
                        >ORDER PENDING<br />
                        DOCUMENTS
                    </template>
                    <template v-slot:icon><i class="fas fa-flag"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showFindOrder">
            <router-link :to="{ name: 'Order Find' }">
                <MenuButton>
                    <template v-slot:label>Find Order</template>
                    <template v-slot:icon><i class="fas fa-search"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showAccounting">
            <router-link :to="{ name: 'Accounting' }">
                <MenuButton>
                    <template v-slot:label>Accounting</template>
                    <template v-slot:icon><i class="far fa-user"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="!showAccounting">
            <router-link :to="{ name: 'User Detail Report' }">
                <MenuButton>
                    <template v-slot:label>User Detail Report</template>
                    <template v-slot:icon><i class="far fa-user"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showForms">
            <router-link :to="{ name: 'Forms' }">
                <MenuButton>
                    <template v-slot:label>Forms</template>
                    <template v-slot:icon><i class="far fa-file-alt"></i></template>
                </MenuButton>
            </router-link>
        </div>

        <div class="mb-4 col-6" :class="column" v-show="showPreReceipt">
            <router-link :to="{ name: 'Pre-Receipt' }">
                <MenuButton>
                    <template v-slot:label>Pre-Receipt</template>
                    <template v-slot:icon><i class="fas fa-receipt"></i></template>
                </MenuButton>
            </router-link>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showComplianceDocs">
            <router-link :to="{ name: 'Compliance' }">
                <MenuButton>
                    <template v-slot:label>Compliance Docs</template>
                    <template v-slot:icon><i class="far fa-folder"></i></template>
                </MenuButton>
            </router-link>
        </div>

        <!--
        <div class="mb-4 col-6" :class="column" v-show="showTopUp">
            <router-link :to="{ name: 'Top Up' }">
                <MenuButton>
                    <template v-slot:label>Top Up</template>
                    <template v-slot:icon><i class="fas fa-mobile-alt"></i></template>
                </MenuButton>
            </router-link>
        </div>
        

        <div class="mb-4 col-6" :class="column" v-show="showBillPayment">
            <MenuButton @click="notImplemented">
                <template v-slot:label>Bill Payment</template>
                <template v-slot:icon><i class="fas fa-file-invoice"></i></template>
            </MenuButton>
        </div>

        <div class="mb-4 col-6" :class="column" v-show="showFindAppClient">
            <MenuButton @click="notImplemented">
                <template v-slot:label>App Client</template>
                <template v-slot:icon><i class="far fa-user-circle"></i></template>
            </MenuButton>
        </div>

        <div class="mb-4 col-6" :class="column" v-show="showInformation">
            <MenuButton @click="notImplemented">
                <template v-slot:label>Information</template>
                <template v-slot:icon><i class="fas fa-cog"></i></template>
            </MenuButton>
        </div>
        <div class="mb-4 col-6" :class="column" v-if="showLogAs">
            <MenuButton @click="notImplemented">
                <template v-slot:label>Log As</template>
                <template v-slot:icon><i class="far fa-file-alt"></i></template>
            </MenuButton>
        </div>

        
        <div class="mb-4 col-6" :class="column" v-show="showUserManual">
            <MenuButton @click="notImplemented">
                <template v-slot:label>User Manual</template>
                <template v-slot:icon><i class="fas fa-book"></i></template>
            </MenuButton>
        </div>
        <div class="mb-4 col-6" :class="column" v-show="showVideos">
            <MenuButton @click="notImplemented">
                <template v-slot:label>Videos</template>
                <template v-slot:icon><i class="far fa-play-circle"></i></template>
            </MenuButton>
        </div>
        -->
    </div>
</template>

<script>
import MenuButton from '@/components/MenuButton';
export default {
    name: 'Menu',
    data() {
        return {};
    },
    props: {
        columns: {
            type: Number,
            default: 4,
        },
    },
    components: {
        MenuButton,
    },
    methods: {
        notImplemented() {
            this.$toast.error('This functionality was not implemented yet');
        },
    },
    computed: {
        column() {
            return 'col-md-' + 12 / this.columns;
        },
        showNewOrder() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && !this.$store.state.agent.permissions.includes('TRAINING') && this.$store.state.agent.canSendTransaction && !this.$store.state.agent.headquarter;
        },
        showPreReceipt() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && !this.$store.state.agent.permissions.includes('TRAINING') && this.$store.state.agent.canSendTransaction && !this.$store.state.agent.headquarter;
        },
        showTopUp() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && !this.$store.state.agent.permissions.includes('TRAINING') && this.$store.state.agent.canSendTransaction && !this.$store.state.agent.headquarter;
        },
        showBillPayment() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && !this.$store.state.agent.permissions.includes('TRAINING') && this.$store.state.agent.canSendTransaction && !this.$store.state.agent.headquarter;
        },
        showFindOrder() {
            return this.$store.state.agent.permissions.includes('FIND_ORDER');
        },
        showFindAppClient() {
            return this.$store.state.agent.permissions.includes('FIND_ORDER');
        },
        showNewClient() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && this.$store.state.agent.canSendTransaction;
        },
        showMessages() {
            return this.$store.state.agent.permissions.includes('MESSAGES');
        },
        showAccounting() {
            return this.$store.state.agent.permissions.includes('ACCOUNTING');
        },
        showInformation() {
            return this.$store.state.agent.permissions.includes('INFORMATION');
        },
        showAdministration() {
            return this.$store.state.agent.permissions.includes('ADMINISTRATION');
        },
        showLogAs() {
            return this.$store.state.agent.permissions.includes('LOG_AS');
        },
        showDisclosure() {
            return this.$store.state.agent.permissions.includes('TRAINING');
        },
        showForms() {
            return this.$store.state.agent.companyType !== 'PaymentAggregator';
        },
        showComplianceDocs() {
            return this.$store.state.agent.companyType !== 'PaymentAggregator';
        },
        showUserManual() {
            return this.$store.state.agent.companyType !== 'PaymentAggregator';
        },
        showVideos() {
            return this.$store.state.agent.companyType !== 'PaymentAggregator';
        },
        showUploadReceipt() {
            return this.$store.state.agent.permissions.includes('ACCOUNTING') && this.$store.state.agent.headquarter;
        },
    },
};
</script>

<style scoped></style>
