<template>
    <div class="form-select dropdown-center cursor-pointer" :class="{ disabled: this.disable, 'opacity-50': this.disable, _class: true }" :id="id" ref="selectCountry" data-bs-toggle="dropdown" data-bs-auto-close="outside" data-bs-reference="parent">
        <div class="cursor-pointer dropdown-toggle" :class="{ disabled: this.disable }" data-bs-toggle="dropdown" data-bs-auto-close="outside" data-bs-reference="parent">
            <gb-flag size="small" :code="getFlag(selected.text)" class="me-2" v-if="selected.value !== ''"></gb-flag><span class="d-inline-flex align-middle">{{ selected.text }}</span>
        </div>
        <div class="dropdown-menu w-100">
            <div class="px-2 pb-2">
                <input type="text" v-model="inputText" class="w-100 form-control" placeholder="Type a country..." ref="inputText" />
            </div>
            <ul class="list-unstyled m-0 overflow-auto">
                <li v-for="(item, index) in filteredData" :key="index">
                    <button type="button" class="dropdown-item py-2" @click="click(item.id)"><gb-flag :code="getFlag(item.value)" class="me-2"></gb-flag>{{ item.value }}</button>
                </li>
                <li v-show="data.length <= 0"><span class="dropdown-item">Loading...</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Dropdown } from 'bootstrap';

export default {
    name: 'SelectCountry',
    props: {
        id: String,
        data: Array,
        value: Number,
        disable: Boolean,
        _class: String,
    },
    data() {
        return {
            selected: {
                text: '- Select -',
                value: '',
            },
            dropdown: null,
            inputText: '',
        };
    },
    mounted() {
        this.dropdown = new Dropdown(this.$refs.selectCountry);

        if (this.value && this.data && this.data.length > 0) {
            this.getItem();
        }

        this.$refs.selectCountry.addEventListener('shown.bs.dropdown', () => {
            this.$refs.inputText.focus();
        });
    },
    methods: {
        click(value) {
            const item = this.filteredData.find((item) => {
                return item.id == value;
            });

            this.selected.text = item.value;
            this.selected.value = item.id;

            this.$emit('input', parseInt(this.selected.value));
            this.$emit('change');

            this.inputText = '';
            this.dropdown.hide();
        },
        getFlag(countryName) {
            switch (countryName) {
                case 'Algeria':
                    return 'dz';
                case 'Argentina':
                    return 'ar';
                case 'Australia':
                    return 'au';
                case 'Austria':
                    return 'at';
                case 'Bangladesh':
                    return 'bd';
                case 'Belgium':
                    return 'be';
                case 'Bolivia':
                    return 'bo';
                case 'Brazil':
                    return 'br';
                case 'Bulgaria':
                    return 'bg';
                case 'Cambodia':
                    return 'kh';
                case 'Cameroon':
                    return 'cm';
                case 'Canada':
                    return 'ca';
                case 'Chile':
                    return 'cl';
                case 'China':
                    return 'cn';
                case 'Colombia':
                    return 'co';
                case 'Costa Rica':
                    return 'cr';
                case 'Croatia':
                    return 'hr';
                case 'Cyprus':
                    return 'cy';
                case 'Czech Republic':
                    return 'cz';
                case 'Denmark':
                    return 'dk';
                case 'Dominican Republic':
                    return 'do';
                case 'Ecuador':
                    return 'ec';
                case 'El Salvador':
                    return 'sv';
                case 'Estonia':
                    return 'ee';
                case 'Ethiopia':
                    return 'et';
                case 'Guatemala':
                    return 'gt';
                case 'Finland':
                    return 'fi';
                case 'France':
                    return 'fr';
                case 'Germany':
                    return 'de';
                case 'Ghana':
                    return 'gh';
                case 'Greece':
                    return 'gr';
                case 'Haiti':
                    return 'ht';
                case 'Honduras':
                    return 'hn';
                case 'Hungary':
                    return 'hu';
                case 'Iceland':
                    return 'is';
                case 'India':
                    return 'in';
                case 'Indonesia':
                    return 'id';
                case 'Ireland':
                    return 'ie';
                case 'Italy':
                    return 'it';
                case 'Jamaica':
                    return 'jm';
                case 'Japan':
                    return 'jp';
                case 'Kenya':
                    return 'ke';
                case 'Latvia':
                    return 'lv';
                case 'Liechtenstein':
                    return 'li';
                case 'Lithuania':
                    return 'lt';
                case 'Luxembourg':
                    return 'lu';
                case 'Malaysia':
                    return 'my';
                case 'Malta':
                    return 'mt';
                case 'Mexico':
                    return 'mx';
                case 'Monaco':
                    return 'mc';
                case 'Myanmar':
                    return 'mm';
                case 'Nepal':
                    return 'np';
                case 'Netherlands':
                    return 'nl';
                case 'Nicaragua':
                    return 'ni';
                case 'Nigeria':
                    return 'ng';
                case 'Norway':
                    return 'no';
                case 'Pakistan':
                    return 'pk';
                case 'Panama':
                    return 'pa';
                case 'Paraguay':
                    return 'py';
                case 'Peru':
                    return 'pe';
                case 'Philippines':
                    return 'ph';
                case 'Poland':
                    return 'pl';
                case 'Portugal':
                    return 'pt';
                case 'Romania':
                    return 'ro';
                case 'Rwanda':
                    return 'rw';
                case 'Singapore':
                    return 'sg';
                case 'Slovakia':
                    return 'sk';
                case 'Slovenia':
                    return 'si';
                case 'South Korea':
                    return 'kr';
                case 'Spain':
                    return 'es';
                case 'Sri Lanka':
                    return 'lk';
                case 'Sweden':
                    return 'se';
                case 'Switzerland':
                    return 'ch';
                case 'Tanzania':
                    return 'tz';
                case 'Thailand':
                    return 'th';
                case 'Turkey':
                    return 'tr';
                case 'Uganda':
                    return 'ug';
                case 'United Kingdom':
                    return 'gb';
                case 'Uruguay':
                    return 'uy';
                case 'USA':
                    return 'us';
                case 'Vietnam':
                    return 'vn';
                case 'Zimbabwe':
                    return 'zw';
            }
        },
        getItem() {
            const country = this.data.find((item) => {
                if (item.id == this.value) return item;
            });
            this.selected.text = country.value;
            this.selected.value = country.id;
        },
    },
    computed: {
        filteredData() {
            return this.inputText.length >= 3
                ? this.data.filter((item) => {
                      return item.value.toLowerCase().includes(this.inputText.toLowerCase());
                  })
                : this.data;
        },
    },
    watch: {
        value() {
            if (this.value && this.data && this.data.length > 0) {
                this.getItem();
            }
        },
        data() {
            if (this.value && this.data && this.data.length > 0) {
                this.getItem();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-toggle {
    border: none;
    background: none;
    &:after {
        content: none;
    }
}
.cursor-pointer {
    cursor: pointer;
}
ul {
    max-height: 300px;
}
img {
    max-width: 2rem;
}
.dropdown-item {
    font-size: 1rem;
}
.form-select {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.form-select-sm {
    font-size: 0.75em !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    img {
        max-width: 1rem;
    }
}
</style>
