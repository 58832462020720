<template>
    <div class="bg-login">
        <div class="gradient vh-100 position-relative">
            <div class="container px-4 p-sm-0">
                <div class="row justify-content-center vh-100 align-items-center">
                    <div class="col-lg-8 col-md-10">
                        <div class="text-center">
                            <img src="@/assets/img/logo-white-inter.png" class="logo w-100 pb-5" alt="Inter&Co Agents" />
                        </div>
                        <div class="card">
                            <div class="card-body p-4">
                                <div class="p-xl-5 p-lg-4">
                                    <div class="alert alert-danger" v-show="hasError" id="loginMessage">
                                        {{ errorMsg }}
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-xl-9 col-md-12 d-flex flex-column justify-content-end">
                                            <div class="row text-start justify-content-center">
                                                <div class="col-lg-6 col-md-8 mt-2">
                                                    <label for="username" class="form-label">Username</label>
                                                    <input type="text" id="username" v-model="username" @keyup.enter="checkCaptcha" class="form-control" placeholder="Your username" :disabled="isLoading" />
                                                </div>
                                                <div class="col-lg-6 col-md-8 mt-2">
                                                    <label for="password" class="form-label">Password</label>
                                                    <input type="password" id="password" v-model="password" @keyup.enter="checkCaptcha" class="form-control" placeholder="Your password" :disabled="isLoading" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5 col-xl-3 d-flex flex-column justify-content-end">
                                            <button class="btn btn-primary text-uppercase mt-4 align-middle" id="login" @click="checkCaptcha" :disabled="isLoading"><i class="fas fa-user"></i> {{ isLoading ? 'Wait...' : 'Log In' }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 d-flex justify-content-center">
                                    <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" @error="onCatchError" size="invisible" badge="inline" :sitekey="captchaId"> </vue-recaptcha>
                                </div>
                            </div>
                            <div class="card-footer text-center border-0 py-2">
                                <button type="button" @click="showAMLTest = !showAMLTest" class="btn btn-link text-dark">AML Test <i class="fas fa-sort-down ms-2 fs-5 pb-1"></i></button>
                                <div class="d-flex">
                                    <ul class="list-group list-group-horizontal mx-auto mt-2" v-show="showAMLTest">
                                        <li class="list-group-item"><a href="https://www.classmarker.com/online-test/start/?quiz=tcc679bcfe392b05" class="text-danger" target="_blank">AML Test English</a></li>
                                        <li class="list-group-item"><a href="https://www.classmarker.com/online-test/start/?quiz=fjt679bd7a67c117" class="text-danger" target="_blank">AML Test Spanish</a></li>
                                        <li class="list-group-item"><a href="https://www.classmarker.com/online-test/start/?quiz=qqa679bd5e3a811d" class="text-danger" target="_blank">AML Test Portuguese</a></li>
                                        <li class="list-group-item"><a href="https://www.surveymonkey.com/r/Z2F8XVC" class="text-success" target="_blank">Annual Review</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="contact row justify-content-center">Contact Agent Support Phone/Whatsapp +1 (877) 766-8825 email: agentsupport@inter.co</div>
                    </div>
                </div>
            </div>
            <footer class="position-absolute w-100 px-4 py-4 d-none d-sm-block">
                <div class="row align-items-center">
                    <div class="col-lg-9 text-md-start text-center">
                        <p class="fw-bold mb-1">Inter & Co Payments, Inc. Agent Interface</p>
                        <p class="mb-3 mb-md-0">This Computer System is property of Inter & Co Payments, Inc. It is for authorized use only. Unauthorized or improper use of this system is forbidden and may result in civil and criminal prosecution.</p>
                    </div>
                    <div class="col-lg-3 text-md-end text-center">Inter © {{ this.currentYear }} - All rights reserved</div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';
import { refreshOrderPendingDocs } from '@/helpers/orderpendingdocs';

export default {
    name: 'Login',
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    data() {
        return {
            username: '',
            password: '',
            isLoading: false,
            hasError: false,
            errorMsg: '',
            showAMLTest: false,
            currentYear: new Date().getFullYear(),
        };
    },
    methods: {
        onCatchError() {
            console.log('Erro ao logar no Recaptcha');
        },
        onCaptchaVerified(token) {
            this.isLoading = true;
            this.hasError = false;
            axios
                .post('/auth/logincaptcha', {
                    username: this.username,
                    password: this.password,
                    captchaToken: token,
                })
                .then((response) => {
                    window.hashApi = response.data.hashApi;
                    this.$store.commit('auth/setLogin', response.data);
                    this.$store.commit('agent/setAgent', response.data.agentInformation);
                    this.$store.dispatch('auth/setRefreshTokenTimer');
                    this.$router.push(this.$route.query.redirect || { name: 'Home' });
                    refreshOrderPendingDocs();
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.hasError = true;
                    this.errorMsg = error.response?.data?.Message || 'Error when logging in';
                });
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        },
        checkCaptcha() {
            this.$refs.recaptcha.execute();
        },
    },
    computed: {
        captchaId() {
            return process.env.VUE_APP_CAPTCHA;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

.contact {
    margin: 20px 0 80px 0;
    color: white;
}

svg {
    margin-right: 5px;
}

.logo {
    max-width: 250px;
}
.gradient {
    background-color: $primary;
}

footer {
    bottom: 0;
    font-size: 10px;
    background-color: $light;
}
</style>
