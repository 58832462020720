<template>
    <table align="center" border="0" cellpadding="0" cellspacing="0" width="980" v-if="data">
        <tbody>
            <tr>
                <td class="bgwhite" height="500" valign="top">
                    <!--   AGENT  COPY   -->
                    <table id="pdfTable" align="center" cellpadding="0" cellspacing="0" class="border_out" style="border-bottom: 1px solid black" valign="top" width="1060">
                        <tbody>
                            <tr>
                                <td colspan="2" rowspan="4" style="padding: 1px">
                                    <img @load="handleLoad" height="88" srcset="@/assets/img/inter-co-logo.svg" alt="Inter Bank" width="200" />
                                </td>
                                <td colspan="2" rowspan="4" width="25%"></td>
                                <td width="25%">Invoice No.: ({{ $t('receipt.invoice-number') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.invoiceNr }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Date/Time: ({{ $t('receipt.date-time') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.orderDateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Date Available: ({{ $t('receipt.date-available') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.dateAvaliable }} (May be available sooner)</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>PIN: (PIN)</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.pin }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" :rowspan="showPixInformation ? 5 : 4" width="28%" v-html="data.license"></td>
                                <td colspan="2" :rowspan="showPixInformation ? 5 : 4" valign="top">
                                    AGENT COPY ({{ $t('receipt.agent-copy') }})<br />
                                    {{ data.agentCompanyName }}<br />
                                    {{ data.agentCompanyGivemId }}<br />
                                    {{ data.agentCompanyAddress }} {{ data.atgentcCompanyAddress }},<br />
                                    {{ data.agentCompanyCity }}, {{ data.agentCompanyState }} - {{ data.agentCompanyCountryName }}<br />
                                    {{ data.agentCompanyTelephone }}
                                </td>
                                <td>Bank/Branch: ({{ $t('receipt.bank-branch') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.beneficiaryBankBranch }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Account Number: ({{ $t('receipt.account-number') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryBankAccountNr }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Account Type: ({{ $t('receipt.account-type') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryBankAccountType }}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="showPixInformation">
                                <td>PIX:<span v-if="data.pixTypeId == '2'">(Inter&Co Agents may add +55)</span></td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryPixId }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>ID Type: ({{ $t('receipt.id-type') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryIdType }}
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="2" rowspan="2">
                                    Sender: ({{ $t('receipt.sender') }})<br />
                                    <span v-show="data.senderCompany">{{ data.senderCompany }} <br /></span>
                                    <span>{{ data.senderFirstName }} {{ data.senderLastName }}</span
                                    ><br />
                                    <span>{{ data.senderCity }}, {{ data.senderState }} {{ data.senderZipCode }}</span>
                                </td>
                                <td colspan="2" rowspan="2">
                                    Telephone: ({{ $t('receipt.telephone') }})<br />
                                    <span>{{ data.senderTelephone1 }}</span>
                                </td>
                                <td>ID Number: ({{ $t('receipt.id-number') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryIdNumber }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Teller: ({{ $t('receipt.teller') }})</td>
                                <td>
                                    <div align="right">
                                        <span style="clear: both">{{ data.teller }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="2" rowspan="3">
                                    Recipient: ({{ $t('receipt.beneficiary') }})<br />
                                    <span v-show="data.beneficiaryCompany">{{ data.beneficiaryCompany }} <br />Attn:</span><span style="">{{ data.beneficiaryFirstName }} {{ data.beneficiaryLastName }}</span>
                                </td>
                                <td colspan="2" rowspan="3">
                                    Telephone: ({{ $t('receipt.telephone') }})<br />
                                    <span>{{ data.beneficiaryTelephone1 }}</span>
                                </td>
                                <td>Transfer Amount: ({{ $t('receipt.transfer-amount') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.amountToBeSentUsd }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Transfer Fees: ({{ $t('receipt.transfer-fee') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span>+{{ data.fee }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Transfer Taxes: ({{ $t('receipt.transfer-taxes') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span>+{{ data.transferTaxes }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td rowspan="2">
                                    Country: ({{ $t('receipt.country') }})<br />
                                    <span>{{ data.beneficiaryCountry }}</span>
                                </td>
                                <td colspan="2" rowspan="2" width="180px">
                                    City: ({{ $t('receipt.city') }})<br />
                                    <span>{{ data.beneficiaryCity }}</span>
                                </td>
                                <td rowspan="2">
                                    State: ({{ $t('receipt.state') }})<br />
                                    {{ data.beneficiaryState }}
                                </td>
                                <td>Total: ({{ $t('receipt.total') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.total }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Exchange Rate: ({{ $t('receipt.exchange-rate') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.exchangeRate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" colspan="2" rowspan="3" valign="bottom">
                                    <br />
                                    ___________________________<br />
                                    Customer Signature<br />
                                    ({{ $t('receipt.customer-signature') }})
                                </td>
                                <td align="center" colspan="2" rowspan="3">
                                    <br />
                                    ___________________________<br />
                                    Teller Signature<br />
                                    ({{ $t('receipt.teller-signature') }})
                                </td>
                                <td>Transfer Amount: ({{ $t('receipt.transfer-amount') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.amountToBeDelivered }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Other Fees: ({{ $t('receipt.other-fees') }})</td>
                                <td>
                                    <div align="right">-{{ data.otherFee }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Total to Recipient: ({{ $t('receipt.total-recipient') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.amountToBeDelivered }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!--    Separation   line-->
                    <table align="center" cellpadding="0" cellspacing="0" class="bottom_dash_border" width="1060">
                        <tbody>
                            <tr>
                                <td class="bottom_dash_border" colspan="2" width="100%">
                                    <div>&nbsp;</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <!--    CLIENT   COPY    -->
                    <table align="center" cellpadding="0" cellspacing="0" class="border_out" style="border-bottom: 1px solid black" valign="top" width="1060">
                        <tbody>
                            <tr>
                                <td colspan="2" rowspan="4" style="padding: 1px">
                                    <img height="88" srcset="@/assets/img/inter-co-logo.svg" alt="Inter Bank" width="200" />
                                </td>
                                <td colspan="2" rowspan="2" width="25%" style="vertical-align: top">Customer Signature ({{ $t('receipt.customer-signature') }})</td>
                                <td>Invoice No.: ({{ $t('receipt.invoice-number') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.invoiceNr }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr style="height: 28px">
                                <td>Date/Time: ({{ $t('receipt.date-time') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.orderDateTime }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2" colspan="2" style="vertical-align: top">Teller Signature ({{ $t('receipt.teller-signature') }})</td>
                                <td>Date Available: ({{ $t('receipt.date-available') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.dateAvaliable }} (May be available sooner)</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>PIN: (PIN)</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.pin }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" :rowspan="showPixInformation ? 5 : 4" width="28%" v-html="data.license"></td>
                                <td colspan="2" :rowspan="showPixInformation ? 5 : 4" valign="top">
                                    AGENT COPY ({{ $t('receipt.agent-copy') }})<br />
                                    {{ data.agentCompanyName }}<br />
                                    {{ data.agentCompanyGivemId }}<br />
                                    {{ data.agentCompanyAddress }} {{ data.atgentcCompanyAddress }},<br />
                                    {{ data.agentCompanyCity }}, {{ data.agentCompanyState }} - {{ data.agentCompanyCountryName }}<br />
                                    {{ data.agentCompanyTelephone }}
                                </td>
                                <td>Bank/Branch: ({{ $t('receipt.bank-branch') }})</td>
                                <td>
                                    <div align="right">
                                        <span>{{ data.beneficiaryBankBranch }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Account Number: ({{ $t('receipt.account-number') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryBankAccountNr }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Account Type: ({{ $t('receipt.account-type') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryBankAccountType }}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="showPixInformation">
                                <td>PIX:<span v-if="data.pixTypeId == '2'">(Inter&Co Agents may add +55)</span></td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryPixId }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>ID Type: ({{ $t('receipt.id-type') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryIdType }}
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="2" rowspan="2">
                                    Sender: ({{ $t('receipt.sender') }})<br />
                                    <span v-show="data.senderCompany">{{ data.senderCompany }} <br /></span>
                                    <span>{{ data.senderFirstName }} {{ data.senderLastName }}</span
                                    ><br />
                                    <span>{{ data.senderCity }}, {{ data.senderState }} {{ data.senderZipCode }}</span>
                                </td>
                                <td colspan="2" rowspan="2">
                                    Telephone: ({{ $t('receipt.telephone') }})<br />
                                    <span>{{ data.senderTelephone1 }}</span>
                                </td>
                                <td>ID Number: ({{ $t('receipt.id-number') }})</td>
                                <td>
                                    <div align="right">
                                        {{ data.beneficiaryIdNumber }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Teller: ({{ $t('receipt.teller') }})</td>
                                <td>
                                    <div align="right">
                                        <span style="clear: both">{{ data.teller }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="2" rowspan="3">
                                    Recipient: ({{ $t('receipt.beneficiary') }})<br />
                                    <span v-show="data.beneficiaryCompany">{{ data.beneficiaryCompany }} <br />Attn:</span> <span style="">{{ data.beneficiaryFirstName }} {{ data.beneficiaryLastName }}</span>
                                </td>
                                <td colspan="2" rowspan="3">
                                    Telephone: ({{ $t('receipt.telephone') }})<br />
                                    <span>{{ data.beneficiaryTelephone1 }}</span>
                                </td>
                                <td>Transfer Amount: ({{ $t('receipt.transfer-amount') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.amountToBeSentUsd }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Transfer Fees: ({{ $t('receipt.transfer-fee') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span> +{{ data.fee }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Transfer Taxes: ({{ $t('receipt.transfer-taxes') }})<br /></td>
                                <td>
                                    <div align="right">
                                        <span> +{{ data.transferTaxes }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td rowspan="2">
                                    Country: ({{ $t('receipt.country') }})<br />
                                    <span>{{ data.beneficiaryCountry }}</span>
                                </td>
                                <td colspan="2" rowspan="2" width="180px">
                                    City: ({{ $t('receipt.city') }})<br />
                                    <span>{{ data.beneficiaryCity }}</span>
                                </td>
                                <td rowspan="2">
                                    State: ({{ $t('receipt.state') }})<br />
                                    {{ data.beneficiaryState }}
                                </td>
                                <td>Total: ({{ $t('receipt.total') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.total }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td>Exchange Rate: ({{ $t('receipt.exchange-rate') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.exchangeRate }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" rowspan="3">
                                    Payee Bank: ({{ $t('receipt.payee-bank') }})<br />
                                    {{ data.payeeBank }}
                                </td>
                                <td colspan="2" rowspan="3">
                                    Telephone: ({{ $t('receipt.telephone') }})<br />
                                    {{ data.payeeBankPhone }}
                                </td>
                                <td>Transfer Amount: ({{ $t('receipt.transfer-amount') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.amountToBeDelivered }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Other Fees: ({{ $t('receipt.other-fees') }})</td>
                                <td>
                                    <div align="right">-{{ data.otherFee }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Total to Recipient: ({{ $t('receipt.total-recipient') }})</td>
                                <td>
                                    <div align="right">
                                        <span> {{ data.amountToBeDelivered }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!--    Orientações    -->
                    <div class="content disclosure_new">
                        <span>
                            <b>Error Resolution and Cancellation Disclosure:</b> You have a right to dispute errors in your transaction. If you think there is an error, contact us within 180 days at 1-877-766-8825 or https://us.inter.co. You can also contact us for a written explanation of your rights. You can cancel for a full refund within 30 minutes of payment unless the funds have been picked up or deposited. For questions or complaints about Inter & Co Payments, Inc. contact: <b v-html="data.stateDisclaimer"></b> or Consumer Financial Protection Bureau 855-411-2372, 855-729-2372 (TTY/TDD) www.consumerfinance.gov. Recipient may receive less due to fees charged by the recipient's bank and foreign taxes. {{ data.haitiFeeEnglish }}
                            In some countries, the payer institution may use the rule of rounding off the amount to be paid at the final destination.
                            <b>Consumer Commitment:</b> By signing this receipt, the consumer accepts the terms and conditions included in this receipt and acknowledges and agrees that you are providing Inter & Co Payments, Inc., with true, accurate, current, and complete information, and that will not use Inter & Co Payments, Inc. services for or in connection with any prohibited activity. Where applicable, bank account information must be accurate, as the money may not be recoverable if deposited to the wrong account. Customer must be 18 years or applicable State legal consent age to use this service. <b>Date of Funds Availability:</b> Your transaction may be delayed or cancelled by certain aspects of the service included but not limited to: identity verification, validation of your transaction's instructions, fraud, compliance review, currency availability of our service providers, or ultimately unavailability due the compliance with the applicable laws or holidays. Nevertheless,
                            you may be entitled to a refund in accordance with our terms of use and subject to applicable laws. <b>Consumer Fraud Warning:</b> If you are sending money based on an uncertain promise of goods or services, for the collection of a prize, or to an unknown party, you may be a victim of fraud. If you think you have been or might be a victim of fraud, please contact us immediately by telephone at 1-877-766-8825.

                            <div v-show="data.locale">
                                <b>{{ $t('receipt.error-resolution-and-cancellation-disclosure-title') }}</b>
                                {{ $t('receipt.error-resolution-and-cancellation-disclosure-text-1') }}
                                <b v-html="data.stateDisclaimer"></b>
                                {{ $t('receipt.error-resolution-and-cancellation-disclosure-text-2') }}
                                {{ data.haitiFeeSpanish }}
                                {{ $t('receipt.error-resolution-and-cancellation-disclosure-text-3') }}
                                <b>{{ $t('receipt.consumer-commitment-title') }}</b>
                                {{ $t('receipt.consumer-commitment-text') }}
                                <b>{{ $t('receipt.date-of-funds-availability-title') }}</b>
                                {{ $t('receipt.date-of-funds-availability-text') }}
                                <b>{{ $t('receipt.consumer-fraud-warning-title') }}</b>
                                {{ $t('receipt.consumer-fraud-warning-text') }}
                            </div>
                            <div v-show="showRightToRefund">
                                <b v-show="showRightToRefund">RIGHT TO REFUND</b> You, the customer, are also entitled to a refund of the money to be transmitted as the result of this agreement if Inter & Co Payments, Inc. does not forward the money received from you within 10 days of the date of its receipt, or does not give instructions committing an equivalent amount of money to the person designated by you within 10 days of the date of the receipt of the funds from you unless otherwise instructed by you. If your instructions as to when the moneys shall be forwarded or transmitted are not complied with and the money has not yet been forwarded or transmitted, you have a right to a refund of your money. If you want a refund, you must mail or deliver your written request to Inter & Co Payments, Inc., 111 N. Sepulveda Blvd., Suite 340, Manhattan Beach, CA 90266. If you do not receive your refund, you may be entitled to your money back plus a penalty of up to $1,000 and attorney's fees
                                pursuant to section 2102 of the California Financial Code. In addition to the above, you have the following rights: You are entitled to obtain a refund or request that Inter & Co Payments, Inc. make funds available to the designated recipient, without additional cost to you or the designated recipient, if following the assertion of an error, which includes failure to make funds available on the date of availability, within 180 days of your transaction, Inter & Co Payments, Inc. determines that an error occurred

                                <b v-show="data.locale">{{ $t('receipt.right-to-refund-title') }}</b>
                                <b v-show="data.locale"> {{ $t('receipt.right-to-refund-text') }}</b>
                            </div>
                        </span>
                    </div>
                    <loading :show="loading"></loading>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { getOrderReceived, persistOrdersReceiptDetail } from '../../helpers/orders';
import { getOrderReceiptDetail } from '../../services/orders';
import Loading from '@/components/Loading';

export default {
    name: 'OrderReceipt',
    components: {
        Loading,
    },
    props: {
        orderId: Number,
        printOnLoad: Boolean,
    },
    data() {
        return {
            data: null,
            loading: false,
            dataLoaded: false,
            imgLoaded: false,
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            const order = getOrderReceived(this.orderId);
            if (!order) {
                try {
                    this.loading = true;
                    const response = await getOrderReceiptDetail(this.orderId);
                    this.data = response.data;
                    this.$i18n.locale = this.data.locale;
                    const dataToStore = { id: this.orderId, data: response.data };

                    persistOrdersReceiptDetail(dataToStore);

                    this.dataLoaded = true;
                    this.checkReadyToPrint();
                } catch (error) {
                    this.data = null;
                    this.$toast.error('Order not found!');
                } finally {
                    this.loading = false;
                }
            } else {
                this.data = order.data;
                this.$i18n.locale = this.data.locale;

                if (this.printOnLoad) {
                    this.dataLoaded = true;
                    this.checkReadyToPrint();
                }
            }
        },
        handleLoad() {
            this.loading = false;
            this.imgLoaded = true;
            this.checkReadyToPrint();
        },
        checkReadyToPrint() {
            if (this.dataLoaded && this.imgLoaded && !this.loading) {
                window.print();
            }
        },
    },
    computed: {
        showRightToRefund() {
            return this.data.showRightToRefund;
        },
        showBankDepositInformation() {
            return this.data.paymentMethod.toUpperCase() === 'BANK_DEPOSIT';
        },
        showPixInformation() {
            return this.data.paymentMethod.toUpperCase() === 'PIX';
        },
        showBoletoInformation() {
            return this.data.paymentMethod.toUpperCase() === 'BOLETO';
        },
        showCashPickUpInformation() {
            return this.data.paymentMethod.toUpperCase() === 'CASH_PICKUP';
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    max-width: 1060px;
}
table {
    font-family: Arial, Helvetica, sans-serif !important;
}
.invoice-header {
    display: contents;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

.disclosure_new {
    margin-top: 3px;
    font-family: Arial, sanserif;
    text-align: justify;
    line-height: 95%;
}

.right_to_refund {
    font-family: Arial, sanserif;
    text-align: justify;
    line-height: 95%;
}

table,
table td {
    border: solid black;
}

table {
    font-size: 12px;
    border-width: 0px 0px 0px 0px;
}

table td {
    border-width: 0px 0px 0px 0px;
}

table.border_out {
    border-width: 1px 1px 1px 1px;
    border-collapse: collapse;
}

borderCell {
    border-width: 1px 1px 1px 1px;
    border-collapse: collapse;
}

table.border_out td {
    border-width: 1px 1px 1px 1px;
    border-collapse: collapse;
}

.bottom_dash_border {
    border: dashed black;
    border-width: 0 0 2px 0px;
}

table.no_border {
    border-width: 0 0 0 0;
    border: none;
}

.underline {
    text-decoration: underline;
}
</style>
