<template>
    <nav class="mt-4" v-show="totalPages > 0">
        <div class="d-flex justify-content-between bd-highlight mb-3">
            <div class="p-2">
                <h1 v-show="selectedSize" class="pagination page-title text-primary text-uppercase">
                    <select class="page-link" v-model="selectedSize" @change="changePageSize(pageSize)">
                        <option value="10" selected>Showing 10</option>
                        <option value="20">Showing 20</option>
                        <option value="50">Showing 50</option>
                        <option value="100">Showing 100</option>
                    </select>
                </h1>
            </div>
            <div class="p-2">
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        <button v-show="actualPage != 1" type="button" class="page-link" @click="changePage(actualPage - 1)" :disabled="actualPage === 1">
                            <span aria-hidden="true">&laquo; Previous Page</span>
                        </button>
                    </li>
                    <select v-model="selectedOption" class="page-link" @change="handleOptionChange">
                        <option class="page-item" v-for="pageNumber in pagesToShow" :key="pageNumber" :value="pageNumber">Page {{ pageNumber }}</option>
                    </select>
                    <li class="page-item">
                        <button v-show="actualPage != totalPages" type="button" class="page-link" @click="changePage(actualPage + 1)" :disabled="actualPage === totalPages">
                            <span aria-hidden="true">&raquo; Next Page </span>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="p-2"></div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        actualPage: Number,
        totalPages: Number,
        pageSize: Number,
    },
    methods: {
        changePage(page) {
            this.selectedOption = page;
            this.$emit('change', page);
        },
        handleOptionChange() {
            // Your logic here
            this.changePage(this.selectedOption, this.pageSize);
        },
        changePageSize(size) {
            this.selectedSize = size;
            this.actualPage = 1;
            this.$emit('changePageSize', size);
            this.$emit('change', this.actualPage);
        },
    },
    computed: {
        selectedOption: {
            get: function () {
                return this.actualPage;
            },
            set: function (modifiedValue) {
                this.actualPage = modifiedValue;
            },
        },
        selectedSize: {
            get: function () {
                return this.pageSize;
            },
            set: function (modifiedValue) {
                this.pageSize = modifiedValue;
            },
        },
        pagesToShow() {
            return this.totalPages;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables';
.active {
    background-color: $primary;
}
</style>
