<template>
    <div class="position-relative d-print-none">
        <nav class="navbar navbar-expand navbar-dark bg-primary" :class="getUrl().includes('new-order') ? 'small' : ''">
            <div class="container">
                <router-link to="/"><img src="@/assets/img/logo-white-inter.png" class="logo w-100" alt="Inter&Co Agents" /></router-link>
                <ul class="navbar-nav navbar-links" v-show="showNavBarLinks">
                    <li class="nav-item text-center d-none d-lg-block">
                        <router-link to="/new-order" active-class="active" class="nav-link px-3 py-3 text-uppercase" v-show="showNewOrder" :name="'btnNavNewOrder'" id="btnNavNewOrder">New Order</router-link>
                    </li>
                    <li class="nav-item text-center d-none d-lg-block" style="position: relative">
                        <router-link :to="{ name: 'Compliance Approval Task' }" active-class="active" class="nav-link px-3 py-3 text-uppercase" v-show="showNewOrder">
                            Order Pending Docs
                            <span v-if="orderPendingDocsCount > 0" class="badge badge-pill bg-secondary" style="position: absolute; top: 15%; right: 2.5%">
                                {{ orderPendingDocsCount }}
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item text-center d-none d-lg-block">
                        <router-link to="/order/find" active-class="active" class="nav-link px-3 py-3 text-uppercase" v-show="showFindOrder">Find Order</router-link>
                    </li>
                    <li class="nav-item text-center">
                        <a href="#" @click="toggleSubMenu" class="nav-link px-3 py-3 text-uppercase" :class="[{ active: subMenuIsOpen }]">More</a>
                    </li>
                    <li class="nav-item text-center">
                        <router-link to="/contact" active-class="active" class="nav-link px-3 py-3 text-uppercase">Contact us</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item dropdown border-end border-light my-2 pe-4 me-3" v-if="isAgent">
                        <balance></balance>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link d-flex dropdown-toggle text-uppercase" href="#" data-bs-toggle="dropdown">
                            <avatar class="">{{ initials }}</avatar>
                            <div class="d-inline-block ms-2">
                                <div class="agent-name">{{ name }}</div>
                                <div class="agent-company-code">{{ companyCode }}</div>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <button @click="logout" class="dropdown-item">Logout</button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
        <div id="subMenu" class="subMenu bg-light position-absolute w-100" :class="{ 'd-block': subMenuIsOpen, 'd-none': !subMenuIsOpen }">
            <div class="container py-5">
                <Menu :columns="6"></Menu>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '@/components/Menu';
import Avatar from '@/components/Avatar';
import Balance from '@/components/Balance';
import { getOrderPendingDocs } from '@/helpers/orderpendingdocs';

export default {
    name: 'Navbar',
    props: {
        showNavBarLinks: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Menu,
        Avatar,
        Balance,
    },
    data() {
        return {
            subMenuIsOpen: false,
            orderPendingDocsCount: 0,
        };
    },
    async beforeMount() {
        if (!this.$store.state.balance.data) this.$store.dispatch('getAccountingBalance');
        this.orderPendingDocsCount = await this.getOrderPendingDocsCount();
    },
    methods: {
        getUrl() {
            return window.location.href;
        },
        toggleSubMenu() {
            this.subMenuIsOpen = !this.subMenuIsOpen;
        },
        async logout() {
            await this.$store.dispatch('auth/logout');
            await this.$store.dispatch('removeShowComplianceSigns');
            //localStorage.setItem('showRemessaPremiada', 'true'); //Código comentado devido ao fim da promoçao. Nao foi removido pra facilidar a ativaçao da mesma quando necessário.
            await this.$router.push('/login');
        },
        async getOrderPendingDocsCount() {
            return await getOrderPendingDocs();
        },
    },
    computed: {
        name() {
            return this.$store.state.agent ? this.$store.state.agent.fullName.split(' ')[0] : '';
        },
        initials() {
            if (this.$store.state.agent) {
                const names = this.$store.state.agent.fullName.split(' ');
                const firstLetterFirstName = names[0].split('')[0];
                const firstLetterSecondName = names[1].split('')[0];
                return firstLetterFirstName + firstLetterSecondName;
            } else return '';
        },
        companyCode() {
            return this.$store.state.agent.companyCode;
        },
        showNewOrder() {
            return this.$store.state.agent.permissions.includes('NEW_ORDER') && !this.$store.state.agent.permissions.includes('TRAINING') && this.$store.state.agent.canSendTransaction && !this.$store.state.agent.headquarter;
        },
        showFindOrder() {
            return this.$store.state.agent.permissions.includes('FIND_ORDER');
        },
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
    },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables';
.logo {
    max-width: 170px;
}

.agent-name {
    display: block;
    font-size: 0.9rem;
    line-height: 1.2rem;
}

.agent-company-code {
    font-size: 0.8rem;
    font-weight: bold;
    color: darken($primary, 30);
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
    .nav-link:hover {
        color: white;
    }
    .dropdown-toggle::after {
        content: none;
    }
}

.navbar-links {
    padding-top: 0;
    padding-bottom: 0;
    .nav-link {
        border-bottom: 5px solid transparent;
    }
    .nav-link:hover,
    .active {
        border-bottom: 5px solid $light;
    }
}

.subMenu {
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
}
</style>
