<template>
    <layout :show-navbar-links="false">
        <div class="container py-4">
            <div class="row justify-content-around">
                <div class="col-md-8">
                    <h1 class="page-title text-primary mb-4 text-uppercase">AML Training and Annual Review</h1>
                    <p class="fw-bold fst-italic">Caro Agente,</p>
                    <p>Como vocês devem saber, precisamos que completem a <span class="text-decoration-underline fw-bold">Revisão Anual</span> e <span class="text-decoration-underline fw-bold">Treinamento de AML</span>.</p>
                    <p>O material de treinamento e revisão anual vão ser disponibilizadas através de links que se encontram na nossa página principal e estarão disponíveis a partir de hoje (04/02/2025).</p>
                    <p>A data prevista para cumprimento dessas tarefas é até o dia <span class="fw-bold fst-italic">27/02/2025</span>. Por favor seguir instruções providas em cada link para completar cada tarefa.</p>

                    <div class="alert alert-danger fs-6">Se deixar de fazê-los até a <span class="fw-bold text-decoration-underline">data de vencimento (27/02/2025)</span>, você será bloqueado no sistema e não será capaz de colocar transações.</div>

                    <p>Somos gratos pela sua colaboração.</p>

                    <h3 class="page-title text-primary fs-5">1. AML Test (English, Portuguese or Spanish)</h3>
                    <p>Conforme requer a lei, uma vez ao ano temos que prover o treinamento de Anti-Lavagem de Dinheiro. O teste precisa ser preenchido por cada indivíduo com acesso ao sistema e também o gerente e compliance officer da loja.</p>

                    <h3 class="page-title text-primary fs-5">2. Annual Review</h3>
                    <p>Esse formulário se trata de um requerimento da Lei Federal (Bank Secrecy Act), o qual deve ser preenchido por um indivíduo que não seja o Compliance Officer do estabelecimento e tão quanto não reporte ao mesmo.</p>

                    <div class="row justify-content-center my-5">
                        <div class="col-md-10">
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <img src="@/assets/img/compliance-aml.png" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="fw-bold fst-italic">Dear Agent,</p>
                    <p>It’s that time of the year...we need to have you complete <span class="fw-bold text-decoration-underline">AML Training</span> and <span class="fw-bold text-decoration-underline">Annual Review</span>.</p>
                    <p>The AML training material and Annual Review are available by clicking on links on our home page (see print screen above).</p>

                    <p>Both training and annual review are due by <span class="fw-bold fst-italic">02/27/2025</span>.</p>

                    <div class="alert alert-danger fs-6">Failing to do it by the <span class="fw-bold text-decoration-underline">DUE DATE (02/27/2025)</span>, you will be blocked from the system and you will not be able to place transactions.</div>

                    <div class="row justify-content-center">
                        <div class="col-md-3 text-center">
                            <button type="button" @click="ok" id="btnAmlOk" class="btn btn-sm btn-primary w-100 text-uppercase">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import moment from 'moment';
import Layout from '@/views/layouts/Main';

export default {
    name: 'AML',
    components: {
        Layout,
    },
    mounted() {
        this.$store.commit('setExpireComplianceAML', null);
    },
    methods: {
        async ok() {
            await this.$store.commit('setExpireComplianceAML', moment().add(1, 'days').format('yyyyMMDD'));
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>
