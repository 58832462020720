<template>
    <Layout>
        <div class="content d-flex flex-column mt-5 mb-4">
            <loading :show="loading" />
            <div v-if="data">
                <div class="container">
                    <div class="card mb-4 invoice">
                        <div class="card-body">
                            <div class="d-flex mb-3">
                                <div class="invoice-header">
                                    <div>
                                        <h1 class="me-auto text-primary fs-5 fw-bold text-uppercase" id="pageTitle">
                                            Invoice# {{ data.invoiceId }} <span class="badge ms-2" :style="{ 'background-color': data.statusColor }">{{ data.status }}</span>
                                            <span v-if="data.isErrorResolution" v-show="permissions.buttonPermissions.errorResolution" class="badge ms-2" :style="{ 'background-color': 'black' }">IS ERROR RESOLUTION</span>
                                        </h1>
                                        <div class="">{{ data.createTime }}</div>
                                    </div>
                                </div>
                                <div class="ms-auto text-lg-end">
                                    <button v-if="!isAgent && canGoToXBOrder" type="button" @click="goToXBOrder" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3">Go to XB Order <i class="fas fa-external-link-alt"></i></button>

                                    <button v-if="!isAgent && canGoToXBInbound" type="button" @click="goToXBInbound" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3">Go to XB Inbound <i class="fas fa-external-link-alt"></i></button>

                                    <button type="button" @click="openLog" class="btn btn-outline-primary btn-sm bg-white text-uppercase">
                                        Show Log <span class="badge bg-secondary ms-2">{{ data.notesCount }}</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="fw-bold mb-1">Sending Agent:</label>
                                    <p class="mb-1">
                                        {{ data.agentName }}<br />
                                        {{ data.agentCode }}<br />
                                        {{ data.agentCity ? `${data.agentCity},` : '' }} {{ data.agentState }} {{ data.agentCountryIso3Code }}
                                    </p>
                                    <label class="fw-bold">MTS-ID:</label> {{ data.agentId }}
                                </div>
                                <div class="col-md-3">
                                    <label class="fw-bold mb-1">Agent REF#:</label>
                                    <p>{{ data.agentOrderRef }}</p>
                                    <label class="form-label">Agent Phone:</label>
                                    <p class="mb-0">{{ data.agentPhone }}</p>
                                </div>
                                <div class="col-md-3">
                                    <label class="fw-bold mb-1">Correspondent:</label>
                                    <p class="mb-1">
                                        {{ data.correspondentName }}<br />
                                        {{ data.correspondentCity }}, {{ data.correspondentState }} {{ data.correspondentCountryIso3Code }}
                                    </p>
                                    <label class="fw-bold">MTS-ID:</label> {{ data.correspondentId }} / {{ data.correspondentCode }}
                                </div>
                                <div class="col-md-3">
                                    <label class="fw-bold mb-1">Correspondent REF#:</label>
                                    <p class="mb-0">{{ data.correspondentOrderRef }}</p>
                                    <div v-show="data.reroutedId">
                                        <label class="fw-bold mb-1">Rerouted Id:</label>
                                        <p class="mb-0">{{ data.reroutedId }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4 sender">
                        <div class="card-body">
                            <div class="d-flex">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">Sender #{{ data.senderId }}</h2>
                                <div class="ms-auto">
                                    <button v-if="data.isUsendAgent" type="button" @click="goToXBDocuments" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3">XB Admin Documents <i class="fas fa-external-link-alt"></i></button>
                                    <div v-if="historyIsDisabled" :title="historyButtonTitle">
                                        <button type="button" id="historyBtn" :disabled="historyIsDisabled" @click="openHistory('sender')" class="btn btn-outline-primary btn-sm text-uppercase">History</button>
                                    </div>
                                    <button v-else type="button" :title="historyButtonTitle" id="historyBtn" :disabled="historyIsDisabled" @click="openHistory('sender')" class="btn btn-outline-primary btn-sm text-uppercase">History</button>
                                </div>
                            </div>
                            <h3 v-html="data.senderName" class="fs-6 mb-3"></h3>
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="fw-bold mb-2">Address:</label>
                                    <p class="mb-1">
                                        {{ data.senderAddress }}
                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <div><label class="fw-bold">Phone 1:</label> {{ data.senderPhone1 }}</div>
                                    <div><label class="fw-bold" v-show="data.senderPhone2">Phone 2:</label> {{ data.senderPhone2 }}</div>
                                    <div><label class="fw-bold">DOB:</label> {{ data.senderDOB }}</div>
                                    <div><label class="fw-bold" v-show="data.senderOccupation">Ocupation:</label> {{ data.senderOccupation }}</div>
                                    <div><label class="fw-bold" v-show="data.senderRecipientRelantion">Relashionship to sender:</label> {{ data.senderRecipientRelantion }}</div>
                                </div>
                                <div class="col-md-6">
                                    <label class="fw-bold mb-3">Required document information</label>
                                    <div class="input-group input-group-sm mb-3">
                                        <button v-show="permissions.buttonPermissions.uploadDocumentSender" @click="openUploadDocumentFile('doc1FileUpload')" class="btn btn-outline-primary" type="button"><i class="fas fa-upload"></i> Upload</button>
                                        <a :href="data.senderDocument1File ? data.senderDocument1File : '#'" v-show="data.senderDocument1File" target="_blank" class="btn btn-outline-primary"><i class="far fa-images"></i> Open</a>
                                        <input type="text" class="form-control" placeholder="" v-model="data.senderDocuments1" disabled />
                                    </div>
                                    <div class="input-group input-group-sm">
                                        <button v-show="permissions.buttonPermissions.uploadDocumentSender" @click="openUploadDocumentFile('doc2FileUpload')" class="btn btn-outline-primary" type="button"><i class="fas fa-upload"></i> Upload</button>
                                        <a :href="data.senderDocument2File ? data.senderDocument2File : '#'" v-show="data.senderDocument2File" target="_blank" class="btn btn-outline-primary"><i class="far fa-images"></i> Open</a>
                                        <input type="text" class="form-control" placeholder="" v-model="data.senderDocuments2" disabled />
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-3 border-0">
                                <div class="card-body bg-light rounded-3 px-4">
                                    <div class="text-center" v-show="!senderAggregation && !loadingSenderAggregation">
                                        <label class="d-block fw-bold mb-1">Sender Activities</label>
                                        <button type="button" @click="getSenderAggregation" class="text-primary bg-light border-0"><i class="fas fa-sync-alt"></i> Load</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-grow text-primary" v-show="loadingSenderAggregation">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="row" v-if="senderAggregation">
                                        <div class="col-md-4">
                                            <label class="fw-bold">Activity in past 30 days</label><br />
                                            ({{ senderAggregation.transactions30Days }}) ${{ senderAggregation.aggregation30DaysFormated }}
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <label class="fw-bold">90 days</label><br />
                                            ({{ senderAggregation.transactions90Days }}) ${{ senderAggregation.aggregation90DaysFormated }}
                                        </div>
                                        <div class="col-md-4 text-end">
                                            <label class="fw-bold">365 days</label><br />
                                            ({{ senderAggregation.transactions365Days }}) ${{ senderAggregation.aggregation365DaysFormated }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4 recipient">
                        <div class="card-body">
                            <div class="d-flex">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">RECIPIENT #{{ data.recipientId }}</h2>
                                <button type="button" @click="openHistory('recipient')" class="btn btn-outline-primary btn-sm text-uppercase ms-auto">History</button>
                                <button type="button" @click="openCPFHistory('recipient')" class="btn btn-outline-primary btn-sm text-uppercase ms-2" v-show="showCPFHistoryButton">CPF History</button>
                            </div>
                            <h3 v-html="data.recipientName" class="fs-6 mb-6"></h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="fw-bold mb-2">Address:</label>
                                    <p class="mb-1">
                                        {{ data.recipientAddress }}
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <div v-show="data.recipientCPF"><label class="fw-bold">CPF/CNPJ:</label> {{ data.recipientCPF }}</div>
                                    <div v-show="data.recipientPhone1"><label class="fw-bold">Phone 1:</label> {{ data.recipientPhone1 }}</div>
                                    <div v-show="data.recipientPhone2"><label class="fw-bold">Phone 2:</label> {{ data.recipientPhone2 }}</div>
                                </div>
                                <div class="col-md-4" v-show="showBankDeposit">
                                    <div v-show="data.recipientCashPickUpInformation"><label class="fw-bold">Location.:</label> {{ data.recipientCashPickUpInformation }}</div>
                                    <div v-show="data.recipientBankName"><label class="fw-bold">Bank Name:</label> {{ data.recipientBankName }}</div>
                                    <div v-show="data.recipientBankAba"><label class="fw-bold">Bank ABA No:</label> {{ data.recipientBankAba }}</div>
                                    <div v-show="data.recipientBankBranch"><label class="fw-bold">Branch:</label> {{ data.recipientBankBranch }}</div>
                                    <div v-show="data.recipientBankAccountNumber"><label class="fw-bold">Account No:</label> {{ data.recipientBankAccountNumber }}</div>
                                </div>
                                <div class="col-md-4" v-show="showPIXInformation">
                                    <div><label class="fw-bold">Pix Type Key:</label> {{ data.recipientBankPixKeyTypeDescription }}</div>
                                    <div><label class="fw-bold">Pix Key:</label> {{ data.recipientBankPixCode }}</div>
                                </div>
                                <div class="col-md-4" v-show="showBoletoInformation">
                                    <div><label class="fw-bold">Boleto No.:</label> {{ data.boletoNumber }}</div>
                                </div>
                                <div class="col-md-4" v-show="showCashInformation">
                                    <div v-show="data.recipientCashPickUpInformation"><label class="fw-bold">Cash Pickup Location.:</label> {{ data.recipientCashPickUpInformation }}</div>
                                    <div v-show="data.recipientDocumentType"><label class="fw-bold">Recipient Document Type:</label> {{ data.recipientDocumentType }}</div>
                                    <div v-show="data.recipientDocumentNumber"><label class="fw-bold">Recipient Document Number:</label> {{ data.recipientDocumentNumber }}</div>
                                    <div v-show="data.recipientDocumentExpiration"><label class="fw-bold">Recipient Document Expiration:</label> {{ data.recipientDocumentExpiration }}</div>
                                </div>
                            </div>
                            <div class="card mt-3 border-0">
                                <div class="card-body bg-light rounded-3 px-4">
                                    <div class="text-center" v-show="!recipientAggregation && !loadingRecipientAggregation">
                                        <label class="d-block fw-bold mb-1">Recipient Activities</label>
                                        <button type="button" @click="getRecipientAggregation" class="text-primary bg-light border-0"><i class="fas fa-sync-alt"></i> Load</button>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-grow text-primary" v-show="loadingRecipientAggregation">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="row" v-if="recipientAggregation">
                                        <div class="col-md-4">
                                            <label class="fw-bold">Activity in past 30 days</label><br />
                                            ({{ recipientAggregation.transactions30Days }}) ${{ recipientAggregation.aggregationSent30DaysFormated }} / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived30DaysFormated }}
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <label class="fw-bold">90 days</label><br />
                                            ({{ recipientAggregation.transactions90Days }}) ${{ recipientAggregation.aggregationSent90DaysFormated }} / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived90DaysFormated }}
                                        </div>
                                        <div class="col-md-4 text-end">
                                            <label class="fw-bold">365 days</label><br />
                                            ({{ recipientAggregation.transactions365Days }}) ${{ recipientAggregation.aggregationSent365DaysFormated }} / {{ recipientAggregation.currencyIsoCode }}{{ recipientAggregation.aggregationReceived365DaysFormated }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center amount">
                        <div class="col-md-8 mb-4">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">Amounts</h2>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div><label class="fw-bold">Net Amount:</label></div>
                                            <div><label class="fw-bold">Percent Fee:</label></div>
                                            <div><label class="fw-bold">Flat Fee:</label></div>
                                            <div><label class="fw-bold">Other Fees:</label></div>
                                            <div><label class="fw-bold">Debit card surcharge:</label></div>
                                            <div><label class="fw-bold">Total Fees:</label></div>
                                            <div><label class="fw-bold">Total Collected:</label></div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="fw-bold">${{ data.amountSent }}</div>
                                            <div>${{ data.percentFee }}</div>
                                            <div>${{ data.flatFee }}</div>
                                            <div>${{ data.otherFees }}</div>
                                            <div>${{ data.creditCardSurcharge }}</div>
                                            <div>${{ data.totalFees }}</div>
                                            <div class="fw-bold">${{ data.totalCollected }}</div>
                                        </div>
                                        <div class="col-md-4">
                                            <div><label class="fw-bold">Exchange rate:</label></div>
                                            <div><label class="fw-bold">Amount to be paid:</label></div>
                                            <div><label class="fw-bold">Currency of payment:</label></div>
                                            <div><label class="fw-bold">Service:</label></div>
                                        </div>
                                        <div class="col-md-3">
                                            <div>{{ data.exchangeRate }}</div>
                                            <div class="fw-bold text-primary">{{ data.currencySymbol }}{{ data.amountReceived }}</div>
                                            <div>{{ data.currencyDescription }} ({{ data.currencyIso3Code ? data.currencyIso3Code : 'n/d' }})</div>
                                            <div>{{ data.paymentMethod }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 h-100">
                            <div class="card">
                                <div class="card-body">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">Other Information</h2>
                                    <div><label class="fw-bold">Purpose of Order:</label> {{ data.orderPurpose }}</div>
                                    <div><label class="fw-bold">Source of Funds:</label> {{ data.sourceFunds }}</div>
                                    <div><label class="fw-bold">Payment Method:</label> {{ data.senderPayMethod }}</div>
                                    <hr />
                                    <div class="d-flex">
                                        <label class="fw-bold">Related Documents</label>
                                        <button type="button" @click="openFileUpload" class="btn btn-sm btn-outline-primary ms-auto"><i class="fas fa-upload"></i> Upload</button>
                                    </div>
                                    <ul v-show="otherInformationDocuments" class="mt-3 list-unstyled">
                                        <li v-for="doc in otherInformationDocuments" :key="doc.recId" class="py-1">
                                            <a :href="doc.imageUrl ? doc.imageUrl : '#'" target="_blank" v-show="doc.imageUrl" class="text-decoration-none text-dark"><i class="far fa-images text-primary fs-5 me-2"></i> {{ doc.fileDescription ? doc.fileDescription : doc.fileName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                    <div class="container d-flex align-items-center justify-content-end" v-if="permissions">
                        <div class="d-flex align-items-center mx-4" v-if="permissions.buttonPermissions.errorResolution">
                            <toggle-button v-model="data.isErrorResolution" @change="onChangeIsErrorResolution" :disabled="loading" class="toggle-orange" />
                            <span class="mx-2 fw-bold">Is Error Resolution</span>
                        </div>
                        <!-- <button type="button" v-show="permissions.buttonPermissions.reconfirm" v-on:click="notImplemented" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Reconfirm</button> -->
                        <button type="button" id="confirmOrderDetail" v-show="permissions.buttonPermissions.confirm" v-on:click="openConfirmOrder" class="btn btn-success btn-sm text-white text-uppercase me-3 mb-3 mb-md-0" :disabled="confirmingOrder">{{ confirmingOrder ? 'Wait...' : 'Confirm Order' }}</button>
                        <button type="button" v-show="permissions.buttonPermissions.confirm2" v-on:click="alertConfirm" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Confirm</button>
                        <button type="button" v-show="permissions.buttonPermissions.edit" v-on:click="editOrder" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Edit</button>
                        <!-- <button type="button" v-show="permissions.buttonPermissions.hold" v-on:click="notImplemented" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Hold</button> -->
                        <!--
                            <button type="button" v-show="permissions.buttonPermissions.stop" v-on:click="notImplemented" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Stop</button>
                            <button type="button" v-show="permissions.buttonPermissions.pay" v-on:click="notImplemented" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Pay</button>
                        -->
                        <router-link :to="{ name: 'Order Receipt', params: { orderId: orderId } }" v-show="permissions.buttonPermissions.receipt" target="_blank" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Receipt</router-link>
                        <router-link :to="{ name: 'Top Up Receipt', params: { orderId: orderId } }" v-show="permissions.buttonPermissions.topupReceipt" target="_blank" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">TopUp Receipt</router-link>
                        <router-link :to="{ name: 'Order Payment Receipt', params: { orderId: orderId } }" v-show="permissions.buttonPermissions.paymentReceipt" target="_blank" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Payment Receipt</router-link>
                        <button type="button" v-show="permissions.buttonPermissions.compForm" v-on:click="openComplianceForm" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Comp. Form</button>
                        <button type="button" v-show="permissions.buttonPermissions.compEdit" v-on:click="openCompExclusive" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Comp. Exclusive</button>
                        <router-link :to="{ name: 'Refund Receipt', params: { orderId: orderId } }" v-show="permissions.buttonPermissions.refundForm" target="_blank" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Refund Form</router-link>
                        <a :href="pdfCancellationFormLink" v-show="permissions.buttonPermissions.signCancelation" target="_blank" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Cancelation Request Form</a>
                        <button type="button" v-on:click="openNotes" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Add a Note</button>
                        <button type="button" v-show="permissions.buttonPermissions.reinstate" v-on:click="notImplemented" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Reinstate</button>
                        <button type="button" v-show="permissions.buttonPermissions.release" v-on:click="openConfirmReleaseOrder" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Release</button>
                        <button type="button" id="approveOrderDetail" v-show="permissions.buttonPermissions.approve" v-on:click="openConfirmApproveOrder" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 mb-3 mb-md-0">Approve</button>
                        <button type="button" v-show="permissions.buttonPermissions.cancel" v-on:click="openCancel" class="btn btn-danger btn-sm text-white text-uppercase me-3 mb-3 mb-md-0">Cancel</button>
                    </div>
                </footer>
                <modal-notes ref="modalNotes" @saved="load"></modal-notes>
                <modal-cancel v-if="orderId" :order-id="orderId" ref="modalCancel" @canceled="load"></modal-cancel>
                <modal-log v-if="orderId" :order-id="orderId" ref="modalLog"></modal-log>
                <modal-client-history :sender-id="data.senderId" :recipient-id="data.recipientId" :recipient-cpf="data.recipientCPF" ref="modalHistory"></modal-client-history>
                <modal-confirm-order ref="modalConfirmOrder" @confirmed="confirmOrder"></modal-confirm-order>
                <modal-confirm-approve-order ref="modalConfirmApproveOrder" @confirmed="approve"></modal-confirm-approve-order>
                <modal-confirm-release-order ref="modalConfirmReleaseOrder" @confirmed="release"></modal-confirm-release-order>
                <modal-cancelation-request ref="modalCancelationRequest" @confirmed="cancelRequest"></modal-cancelation-request>
                <modal-file-upload ref="modalFileUpload" :upload-url="uploadUrl" :show-input-description="true" @uploaded="loadOtherInformationsDocuments"></modal-file-upload>
                <modal-comp-exclusive ref="modalCompExclusive" :client-id="data.senderId"></modal-comp-exclusive>
            </div>
            <modal-file-upload ref="modalUploadDocumentFile" :upload-url="uploadDocumentUrl" @uploaded="load"></modal-file-upload>
            <modal-detail-order-debit-card :can-resend-text-message="canResendTextMessage" :payment-link="debitCardPaymentLink" :order-id="orderId" :phone="senderPhone" ref="modalDetailOrderDebitCard" @reload="load"></modal-detail-order-debit-card>
        </div>
    </Layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import ModalLog from '@/views/order/modals/Log';
import ModalNotes from '@/views/order/modals/Notes';
import ModalCancel from '@/views/order/modals/Cancel';
import ModalFileUpload from '@/views/order/modals/FileUpload';
import ModalConfirmOrder from '@/views/order/modals/ConfirmOrder';
import ModalClientHistory from '@/views/order/modals/ClientHistory';
import pdfCancellationForm from '@/assets/pdf/forms/CancellationForm.pdf';
import ModalCancelationRequest from '@/views/order/modals/CancelationRequest';
import ModalConfirmReleaseOrder from '@/views/order/modals/ConfirmReleaseOrder';
import ModalConfirmApproveOrder from '@/views/order/modals/ConfirmApproveOrder';
import ModalDetailOrderDebitCard from '@/views/order/modals/DetailOrderDebitCard';
import ModalCompExclusive from '@/views/order/modals/ClientComplianceExclusiveModal';

export default {
    name: 'OrderDetail',
    components: {
        Layout,
        Loading,
        ModalLog,
        ModalNotes,
        ModalCancel,
        ModalFileUpload,
        ModalConfirmOrder,
        ModalConfirmApproveOrder,
        ModalConfirmReleaseOrder,
        ModalCancelationRequest,
        ModalClientHistory,
        ModalDetailOrderDebitCard,
        ModalCompExclusive,
    },
    props: {
        orderId: Number,
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
            permissions: null,
            confirmingOrder: false,
            senderAggregation: null,
            sendingTextMessage: false,
            recipientAggregation: null,
            loadingSenderAggregation: false,
            otherInformationDocuments: null,
            loadingRecipientAggregation: false,
            pdfCancellationFormLink: pdfCancellationForm,
            docFileUpload: '',
            document1Object: '',
            document1File: '',
            document2Object: '',
            document2File: '',
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.getOrder();
            this.getPermissions();
            this.loadOtherInformationsDocuments();
        },
        getOrder() {
            this.loading = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail`)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        approve() {
            axios
                .post(`/niloagent/order/${this.orderId}/detail/approve`)
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        release() {
            axios
                .post(`/niloagent/order/${this.orderId}/detail/release`)
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        loadOtherInformationsDocuments() {
            axios
                .get(`/niloagent/order/${this.orderId}/detail/otherInformationDocuments`)
                .then((response) => {
                    this.otherInformationDocuments = response.data;
                })
                .catch((error) => {
                    this.otherInformationDocuments = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        getPermissions() {
            axios
                .get(`/niloagent/order/${this.orderId}/detail/permissions`)
                .then((response) => {
                    this.permissions = response.data;
                    if (response.data.message != null) this.$toast.info(response.data.message);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getSenderAggregation() {
            this.loadingSenderAggregation = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail/senderaggregation/${this.data.senderId}`)
                .then((response) => {
                    this.senderAggregation = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loadingSenderAggregation = false;
                });
        },
        getRecipientAggregation() {
            this.loadingRecipientAggregation = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail/recipientaggregation/${this.data.recipientId}`)
                .then((response) => {
                    this.recipientAggregation = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loadingRecipientAggregation = false;
                });
        },
        saveUploadedFile(file) {
            if (this.docFileUpload === 'doc1FileUpload') {
                this.document1Object = file;
                this.document1File = file.fileKey;
            }

            if (this.docFileUpload === 'doc2FileUpload') {
                this.document2Object = file;
                this.document2File = file.fileKey;
            }
        },
        openUploadDocumentFile(docFileUpload) {
            this.docFileUpload = docFileUpload;
            this.$refs.modalUploadDocumentFile.show();
        },
        openNotes() {
            this.$refs.modalNotes.show(this.orderId);
        },
        openCancelationRequest() {
            this.$refs.modalCancelationRequest.show(this.orderId);
        },
        openCancel() {
            this.$refs.modalCancel.show();
        },
        openLog() {
            this.$refs.modalLog.show();
        },
        openHistory(client) {
            if (client === 'sender') {
                this.senderAggregation = null;
            } else {
                this.recipientAggregation = null;
            }
            this.$refs.modalHistory.show(client);
        },
        openCPFHistory(client) {
            this.recipientAggregation = null;
            this.$refs.modalHistory.showByCPF(client);
        },
        openConfirmOrder() {
            this.$refs.modalConfirmOrder.show();
        },
        openConfirmApproveOrder() {
            this.$refs.modalConfirmApproveOrder.show();
        },
        openConfirmReleaseOrder() {
            this.$refs.modalConfirmReleaseOrder.show();
        },
        alertConfirm() {
            alert('The amount of this order requires authorization from the Central office!');
        },
        confirmOrder() {
            this.confirmingOrder = true;
            axios
                .post('/niloagent/createorder/confirm', { orderId: this.orderId })
                .then((response) => {
                    this.$router.push({ name: 'Order Confirmation', params: { orderId: response.data.orderId } });
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.confirmingOrder = false;
                });
        },
        cancelRequest() {
            alert('implementar');
        },
        notImplemented() {
            this.$toast.error('This functionality was not implemented yet');
        },
        openComplianceForm() {
            let routeData = this.$router.resolve({ name: 'Compliance Form' });
            window.open(routeData.href, '_blank');
        },
        openReceipt() {
            let routeData = this.$router.resolve({ name: 'Order Receipt' });
            window.open(routeData.href, '_blank');
        },
        openFileUpload() {
            this.$refs.modalFileUpload.show();
        },
        editOrder() {
            axios
                .get(`/niloagent/createorder/edit/${this.orderId}`)
                .then((response) => {
                    this.$store.commit('simpleOrder/setSender', response.data.sender);
                    this.$store.commit('simpleOrder/setRecipient', response.data.recipient);
                    this.$store.commit('simpleOrder/setPaymentInformation', response.data.paymentInformation);
                    this.$store.commit('simpleOrder/setAdditionalInformation', response.data.additionalInformation);
                    this.$store.commit('simpleOrder/setReferenceNumber', response.data.orderReferenceNumber);
                    this.$store.commit('simpleOrder/setOrderId', response.data.orderId);

                    this.$router.push({ name: 'Simple Order', params: { blockCreated: true } });
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        onChangeIsErrorResolution(value) {
            this.loading = true;
            axios
                .post(`/niloagent/order/${this.orderId}/detail/errorresolution?resolution=${value}`)
                .then((response) => {
                    this.data.isErrorResolution = value;
                    this.$toast.success(`${response.data}`);
                })
                .catch((error) => {
                    this.data.isErrorResolution = !value;
                    this.$toast.error(error.response.data.errors.resolution[0] || error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openCompExclusive() {
            this.$refs.modalCompExclusive.show();
        },
        goToXBOrder() {
            window.open(`${process.env.VUE_APP_XBADMIN_URL}/remittance/transaction-orders/order-details?orderId=${this.data.agentOrderRef}`, '_blank');
        },
        goToXBInbound() {
            window.open(`${process.env.VUE_APP_XBADMIN_URL}/remittance/inbound-payment/${this.orderId}`, '_blank');
        },
        goToXBDocuments() {
            window.open(`${process.env.VUE_APP_XBADMIN_URL}/remittance/customers/customer-details?clientId=${this.senderId}&section=identification`, '_blank');
        },
    },
    computed: {
        uploadUrl() {
            return `/niloagent/order/${this.orderId}/detail/savefile`;
        },
        uploadDocumentUrl() {
            if (this.docFileUpload === 'doc1FileUpload') {
                return `/niloagent/order/${this.orderId}/detail/updatesenderdocument1file`;
            } else if (this.docFileUpload === 'doc2FileUpload') {
                return `/niloagent/order/${this.orderId}/detail/updatesenderdocument2file`;
            } else return '';
        },
        showBankDeposit() {
            return this.data.paymentMethodType.toUpperCase() === 'BANK_DEPOSIT';
        },
        showPIXInformation() {
            return this.data.paymentMethodType.toUpperCase() === 'PIX';
        },
        showBoletoInformation() {
            return this.data.paymentMethodType.toUpperCase() === 'BOLETO';
        },
        showCashInformation() {
            return this.data.paymentMethodType.toUpperCase() === 'CASH_PICKUP';
        },
        showDebitCardModal() {
            return this.data !== null && this.permissions !== null && this.data.showDebitCardConfirmation;
        },
        showCPFHistoryButton() {
            return this.data.recipientCPF;
        },
        canResendTextMessage() {
            return this.permissions && this.permissions.buttonPermissions.resendTextMessage;
        },
        debitCardPaymentLink() {
            return this.data ? this.data.debitCardPaymentLink : '';
        },
        senderPhone() {
            return this.data ? this.data.senderPhone1 : '';
        },
        senderId() {
            if (!this.data || !this.data.senderId) {
                return '';
            }

            let senderId = this.data.senderId;

            // Defines the regex pattern to match strings starting with 6 or 7
            const pattern = /^[67]/;
            const replacement = '8';

            // Replaces the first character if it meets the condition
            return senderId.replace(pattern, replacement);
        },
        historyIsDisabled() {
            return this.data?.agentCode?.toLowerCase()?.startsWith('pay');
        },
        historyButtonTitle() {
            return this.data?.agentCode?.toLowerCase()?.startsWith('pay') ? 'Feature not available for this sender.' : '';
        },
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
        canGoToXBOrder() {
            return this.data?.agentName?.toUpperCase()?.includes('USEND');
        },
        canGoToXBInbound() {
            return (this.data?.status.toUpperCase() === 'PROCESSING' || this.data?.status.toUpperCase() === 'PAID') && this.data?.correspondentName?.toUpperCase()?.includes('BANCO INTER');
        },
    },
    watch: {
        orderId() {
            this.load();
        },
        showDebitCardModal(value) {
            if (value) {
                this.$refs.modalDetailOrderDebitCard.show();
            } else {
                this.$refs.modalDetailOrderDebitCard.hide();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    padding-bottom: 100px;
}
.card-header {
    font-size: 0.9rem;
}
.badge {
    font-weight: 500;
    font-size: 0.75rem;
}
.invoice-header {
    display: contents;
}
.toggle-orange {
    --toggle-bg-on: var(--bs-primary);
    --toggle-border-on: var(--bs-primary);
    --toggle-bg-off: #bcbcbc;
    --toggle-border-off: #bcbcbc;
}
</style>
