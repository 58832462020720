import OrderFind from '@/views/order/Find';
import OrderDetail from '@/views/order/Detail';
import OrderReceipt from '@/views/order/Receipt';
import ComplianceForm from '@/views/order/ComplianceForm';
import PreReceipt from '@/views/order/receipts/PreReceipt';
import OrderConfirmation from '@/views/order/create/Confirmation';
import PreReceiptPrint from '@/views/order/receipts/PreReceiptPrint';
import OrderPaymentReceipt from '@/views/order/receipts/PaymentReceipt';
import CreditDebitCardPayment from '@/views/order/create/CreditDebitCardPayment';
import NewOrder from '@/views/order/simpleOrder/NewOrder';

export default [
    {
        path: '/new-order',
        name: 'Simple Order',
        component: NewOrder,
    },
    {
        path: '/order/create/confirmation/:orderId',
        name: 'Order Confirmation',
        component: OrderConfirmation,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/find',
        name: 'Order Find',
        component: OrderFind,
        props: true,
    },
    {
        path: '/order/detail/:orderId',
        name: 'Order Detail',
        component: OrderDetail,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/receipt/:orderId',
        name: 'Order Receipt',
        component: OrderReceipt,
        props: (route) => ({
            orderId: parseInt(route.params.orderId, 10),
            printOnLoad: route.query.printOnLoad === 'true',
        }),
    },
    {
        path: '/order/payment-receipt/:orderId',
        name: 'Order Payment Receipt',
        component: OrderPaymentReceipt,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/detail/complianceForm/:orderId',
        name: 'Compliance Form',
        component: ComplianceForm,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/card-payment/:token',
        name: 'Credit Debit Card Payment',
        component: CreditDebitCardPayment,
        props: true,
    },
    {
        path: '/order/pre-receipt',
        name: 'Pre-Receipt',
        component: PreReceipt,
    },
    {
        path: '/order/pre-receipt-print',
        name: 'Pre-Receipt Print',
        component: PreReceiptPrint,
    },
];
